import {
  PerspectiveCamera,
  Scene,
  WebGLRenderer,
  AmbientLight,
  BoxGeometry,
  Mesh,
  Object3D,
  Vector3,
} from 'three';

import {
  reactive,
} from 'vue';

import { Tween, update } from '@tweenjs/tween.js';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import model from '@/assets/models/nils.glb';

import { InteractionManager } from 'three.interactive';

const modalState = reactive({
  openShoulder: false,
  openNeck: false,
  openBack: false,
  openHand: false,
});

const geometry = new BoxGeometry();
const Shoulder = new Mesh(geometry);
const Neck = new Mesh(geometry);
const Back = new Mesh(geometry);
const Hand = new Mesh(geometry);

let active: Object3D = Shoulder;

const cameraPositions: Array<coord> = [
  // hand
  { x: -2.3, y: 8, z: 4 },
  // neck
  { x: 1, y: 17, z: -3 },
  // back
  { x: -0.9, y: 13, z: -3 },
  // shoulder
  { x: 2.3, y: 15, z: 2.3 },
];

let camera: PerspectiveCamera;

type coord = { x: number; y: number; z: number }
const moveCamera = (target: Object3D | null = null) => {
  const from: coord = { x: camera.position.x, y: camera.position.y, z: camera.position.z };
  let to: coord;
  const lookatPrev = { x: active.position.x, y: active.position.y, z: active.position.z };
  let lookat = { x: target?.position.x, y: target?.position.y, z: target?.position.z };
  const [handCam, neckCam, backCam, shoulderCam] = cameraPositions;
  if (target === Hand) {
    to = handCam;
  } else if (target === Neck) {
    to = neckCam;
  } else if (target === Back) {
    to = backCam;
  } else { // shoulder
    to = shoulderCam;
    lookat = { x: Shoulder.position.x, y: Shoulder.position.y, z: Shoulder.position.z };
  }
  // tween position
  new Tween(from)
    .to({ x: to.x, y: to.y, z: to.z }, 1000)
    .onUpdate(() => {
      camera.position.set(from.x, from.y, from.z);
    })
    .start();
  // tween lookat
  new Tween(lookatPrev)
    .to({ x: lookat.x, y: lookat.y, z: lookat.z }, 1000)
    .onUpdate(() => {
      camera.lookAt(new Vector3(lookatPrev.x, lookatPrev.y, lookatPrev.z));
    })
    .start();

  active = target ?? Shoulder;
};

const kontaktScene = (containerElement?: HTMLElement) => {
  // define vars
  let scene: Scene;
  let renderer: WebGLRenderer;

  const init = () => {
    const setContainerElement = containerElement ?? new HTMLElement();
    // IMPORTANT: set renderer properties corresponding to the
    // containerElement parameter. In Three.js examples this is usually the
    // window object.
    camera = new PerspectiveCamera(75,
      setContainerElement.offsetWidth / setContainerElement.offsetHeight, 0.1, 1000);
    camera.position.copy(active.position);

    scene = new Scene();

    // renderer
    renderer = new WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(setContainerElement.offsetWidth, setContainerElement.offsetHeight);
    setContainerElement.appendChild(renderer.domElement);

    // interaction manager
    const interactionManager = new InteractionManager(
      renderer,
      camera,
      renderer.domElement,
    );

    // light
    scene.add(new AmbientLight());

    // objects
    const loader = new GLTFLoader();
    loader.load(model, (gltf) => {
      scene.add(gltf.scene);
    }, undefined, (error) => {
      console.error(`hello ${error}`);
    });

    // const material = new MeshBasicMaterial({ color: 0x00ff00 });
    Shoulder.visible = false;
    Shoulder.position.set(1.2, 14.2, 0.3);
    scene.add(Shoulder);
    Shoulder.addEventListener('click', () => {
      modalState.openShoulder = true;
    });

    Neck.visible = false;
    Neck.position.set(0, 14.6, -0.15);
    scene.add(Neck);
    Neck.addEventListener('click', () => {
      modalState.openNeck = true;
    });

    Back.visible = false;
    Back.position.set(-0.9, 13, -0.6);
    scene.add(Back);
    Back.addEventListener('click', () => {
      modalState.openBack = true;
    });

    Hand.visible = false;
    Hand.position.set(-5, 10.1, 2.6);
    scene.add(Hand);
    Hand.addEventListener('click', () => {
      modalState.openHand = true;
    });

    interactionManager.add(Shoulder);
    interactionManager.add(Neck);
    interactionManager.add(Back);
    interactionManager.add(Hand);

    moveCamera(Shoulder);
  };

  const animate = (time: number) => {
    renderer.render(scene, camera);
    update(time);
    requestAnimationFrame(animate);
  };

  // IMPORTANT: you need to expose the init and animate Funtions for the component to
  // call during its lifecycle
  return {
    init,
    animate,
    moveCamera,
    Hand,
    Neck,
    Shoulder,
    Back,
    modalState,
  };
};

// IMPORTANT: export default!
export default kontaktScene;
