
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Collapse',
  props: {
    title: String,
  },
  setup() {
    const collapsed = ref(true);

    const toggle = () => {
      collapsed.value = !collapsed.value;
    };

    const isCollapsed = computed(() => (collapsed.value ? 'collapsed' : ''));
    const collapsedArrow = computed(() => (collapsed.value ? '↓' : '↑'));

    return {
      toggle,
      isCollapsed,
      collapsedArrow,
    };
  },
});
