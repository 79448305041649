<script lang="ts">
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Collapse',
  props: {
    title: String,
  },
  setup() {
    const collapsed = ref(true);

    const toggle = () => {
      collapsed.value = !collapsed.value;
    };

    const isCollapsed = computed(() => (collapsed.value ? 'collapsed' : ''));
    const collapsedArrow = computed(() => (collapsed.value ? '↓' : '↑'));

    return {
      toggle,
      isCollapsed,
      collapsedArrow,
    };
  },
});
</script>

<template>
  <div class="collapse" :class="isCollapsed">
    <div class="header" :class="isCollapsed" @click="toggle()">
      <h2>{{ `${title}` }}</h2>
      <h2>{{ `${collapsedArrow}` }}</h2>
    </div>
    <div class="content" :class="isCollapsed">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .collapse {
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
    .header {

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      color: red !important;
      &.collapsed {
        color: black !important;
      }

      cursor: pointer;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &.collapsed {
        height: 0 !important;
        overflow: hidden;
        color: black !important;
      }
    }
  }
</style>
