<script lang="ts">
import { defineComponent, computed } from 'vue';

import scene from '../scenes/kontakt';

export default defineComponent({
  name: 'Modal',
  props: {
    title: String,
    animation: String,
    open: Boolean,
  },
  setup(props) {
    const {
      modalState,
    } = scene();

    const close = () => {
      modalState.openShoulder = false;
      modalState.openNeck = false;
      modalState.openHand = false;
      modalState.openBack = false;
    };

    const isOpen = computed(() => (props.open ? '' : 'closed'));

    return {
      close,
      isOpen,
    };
  },
});
</script>

<template>
  <div class="modal" :class="isOpen">
     <div class="header">
      <h1>{{ `${title}` }}</h1>
      <h2 @click="close()" class="close-button">X</h2>
    </div>
    <div class="video-container">
      <video class="animation" controls
        crossOrigin="anonymous">
        <source :src="animation" type="video/mp4">
      </video>
    </div>
    <div class="text-container">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  height: 80vh;
  position: absolute;
  top: 10vh;
  z-index: 999;
  background-color: white;
  width: 100vw;
  border-top: 1px solid black;
  border-bottom: 1px solid black;

  display: flex;
  flex-direction: column;

  &.closed {
    display: none !important;
  }

}
.header {
  padding-left: 1%;
  padding-right: 1%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
  h1 {
    font-stretch: 200% !important;
    font-size: 150%;
  }
}
.video-container {
  height: 50vh;
  width: 100%;
  border-bottom: 1px solid black;
  video {
    max-width: 100%;
  }
}
.text-container {
  padding-left: 1%;
  padding-right: 1%;
  height: 30vh;
  width: 90%;
  overflow: scroll;
}
.close-button {
  color:red;
  cursor: pointer;
}

.animation {
  height: 100%;
}
</style>
