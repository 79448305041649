
import { defineComponent, computed } from 'vue';

import scene from '../scenes/kontakt';

export default defineComponent({
  name: 'Modal',
  props: {
    title: String,
    animation: String,
    open: Boolean,
  },
  setup(props) {
    const {
      modalState,
    } = scene();

    const close = () => {
      modalState.openShoulder = false;
      modalState.openNeck = false;
      modalState.openHand = false;
      modalState.openBack = false;
    };

    const isOpen = computed(() => (props.open ? '' : 'closed'));

    return {
      close,
      isOpen,
    };
  },
});
