
/* eslint-disable max-len */
import { defineComponent } from 'vue';

import ThreeSketch from '../node_modules/@alexpetercharles/vue3-three-component/src/three-sketch.vue';
import Collapse from './components/Collapse.vue';
import Modal from './components/Modal.vue';

import scene from './scenes/kontakt';

export default defineComponent({
  name: 'Kontakt',
  components: {
    Collapse,
    ThreeSketch,
    Modal,
  },
  setup() {
    const {
      Hand,
      Shoulder,
      Neck,
      Back,
      moveCamera,
      modalState,
    } = scene();
    return {
      scene,
      Hand,
      Shoulder,
      Neck,
      Back,
      moveCamera,
      modalState,
    };
  },
});
