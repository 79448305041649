<script lang="ts">
/* eslint-disable max-len */
import { defineComponent } from 'vue';

import ThreeSketch from '../node_modules/@alexpetercharles/vue3-three-component/src/three-sketch.vue';
import Collapse from './components/Collapse.vue';
import Modal from './components/Modal.vue';

import scene from './scenes/kontakt';

export default defineComponent({
  name: 'Kontakt',
  components: {
    Collapse,
    ThreeSketch,
    Modal,
  },
  setup() {
    const {
      Hand,
      Shoulder,
      Neck,
      Back,
      moveCamera,
      modalState,
    } = scene();
    return {
      scene,
      Hand,
      Shoulder,
      Neck,
      Back,
      moveCamera,
      modalState,
    };
  },
});
</script>

<template>
  <section>
    <h1>KÖRPERKONTAKT</h1>
  </section>
  <section>
    <collapse :title="'KONTEXT'">
      <div class="text-block">
        <p>Ich beschäftige mich mit den Kontaktpunkten auf der Haut, die bei Berührungen entstehen. Sie haben für mich eine zentrale Bedeutung, ich verknüpfe damit die unterschiedlichsten Gefühle und Erinnerungen, ich verknüpfe mit ihnen meine Existenz. Die Haut ist für mich ein verlässlicher Gradmesser, ein Seismograf für den Kontakt mit meiner unmittelbaren Umwelt. Sie tauscht Kontaktinformationen von aussen gegen innen, aber auch von innen gegen aussen aus. Sie ist Verbindung und Signal zugleich.</p>
        <br />
        <p>Berührung ist die direkteste Form von Kontakt, die ich mir vorstellen kann. Sie löst alle Gefühle aus, die ich als Mensch empfinden kann: Trauer, Angst, Ekel, Ärger, Überraschung oder Freude. Jede Berührung ist jedoch anders und wird von verschiedenen Menschen unterschiedlich wahrgenommen. Es fliessen Faktoren von aussen ein, zum Beispiel die Umgebung, Situation, Person oder Absicht. Innere Faktoren sind zum Beispiel die aktuelle Gefühlslage, gemachte Erfahrungen oder die Empfindlichkeit der Haut, die die Wahrnehmung einer Berührung so unterschiedlich machen. Bei jeder Verschiebung eines dieser Parameter kann ich mein Bewusstsein schärfen, dass eine Berührung, die ich jetzt als angenehm empfinde, kurze Zeit später in einem leicht anderen Kontext, sehr unangenehm und damit abstossend ist.</p>
        <br />
        <p>Im aktuellen gesellschaftlichen Kontext erhält die Berührung über #metoo eine politische Dimension. Als Mann muss ich mir bewusst werden, was ich mit meinen Berührungen auslösen kann, wann sie erwünscht und damit positiv sind und wann dies nicht der Fall ist. In Zeiten der Pandemie berühren wir uns viel weniger, taktile Begrüssungsrituale fallen ganz weg. Viele Menschen leiden unter dem Mangel an Berührungen. Umso deutlicher tritt die wichtigste Funktion der Haut als Kontaktorgan zutage: Sie verbindet uns Menschen miteinander, sie lässt uns Nähe und Wärme spüren. Auf diesen Kontakt können und wollen wir auf Dauer nicht verzichten.</p>
        <br />
        <p>In meiner Arbeit halte ich fest, was zwischenmenschlicher Kontakt bisher für mich ausgelöst hat. Von der Grundschulzeit, durch die Lehre, bis zu meiner jetzigen Lebensphase.</p>
        <br />
        <p>Als Medium habe ich mich für die Animation entschieden. Über das bewegte Bild kann ich Faktoren wie Dauer der Berührung und Veränderung der Empfindung während der Berührung zeigen. Über die Grafische Abstraktion, die Bewegung und über Ton kann ich eine Berührung zum Leben erwecken und so für den Betrachter mehrdimensional erfahrbar machen<br />
        </p>
        <br />
      </div>
    </collapse>
  </section>
  <section class="sketch-section">
    <three-sketch class="sketch-container" :src="scene" />
    <div class="button-container">
      <div class="button" @click="moveCamera(Shoulder)"><p>ECKEL / <br/>ÄRGER</p></div>
      <div class="button" @click="moveCamera(Neck)"><p>TRAUER / <br/>FURCHT</p></div>
      <div class="button" @click="moveCamera(Back)"><p>FREUDE</p></div>
      <div class="button" @click="moveCamera(Hand)"><p>ÜBERRASCHUNG / <br/>FREUDE</p></div>
    </div>
  </section>
  <section>
    <collapse :title="'PROZESS'">
      <collapse :title="'IDEENPOOL'" :sub="true" class="sub">
        <div class="text-block">
          <img src="./assets/images/Brainstorming.png" />
          <h3>Haut / Berührung → Ausgewählt</h3>
          <p>
            Auseinandersetzung mit der Haut als Kontakt(Tast-)organ des Menschen. Systematischer Prozess zur gestalterischen Umsetzung, die die Haut erforschen. Kontakt mit der Haut löst an verschiedenen Stellen des Körpers verschiedene Emotionen aus.
          </p>
          <h4>Begrüssungsrituale ? Haut als Grenze von Innen gegen Aussen → Grenzen von Kontakt zur Aussenwelt. Hautreaktion → Ausschlag, Trommel → Gespannte Haut
          Wie visualisiert man eine Berührung?</h4>
          <h3>BMI Dystopie (speculative design)</h3>
          <p>Neue Kontaktformen werden auftreten. Eine mögliche ist die neurologische Verbindung der Menschen mit einander und mit der Maschine. Wie fühlt sich das Mensch sein in so einer Verbundenheit an und wie kann ich dieses Gefühl, diesen Zustand gestalterisch umsetzen.</p>
          <h3>Augenkontakt (Mimik)</h3>
          <p>Zu Zeiten von Corona ist der soziale Kontakt stark eingeschränkt. Wir tragen Masken, sowohl in der Öffentlichkeit als auch unter Freunden und Familienmitgliedern. Die führt dazu, dass die Augen zum Zentrum unserer Kommunikation werden. Augenkontakt und Augengestik werden viel bewusster wahrgenommen.</p>
          <h3>Kontaktdruckverfahren</h3>
          <p>Eine gestalterische Auseinandersetzung mit verschiedenen Druckverfahren, die bei Kontakt drucken. Nicht auf dem Gedruckten liegt der Fokus, sondern auf dem Druckwerkzeug. Das Sieb und der Rakel beim Siebdruck, die Linolplatte und die Rolle beim Linoldruck und so weiter.</p>
          <h3>Wackelkontakt/Elektrizität</h3>
          <p>Wo entsteht in der Welt des Stroms Kontakt? Wie stellt man diese Berührungspunkte gestalterisch dar? Formen von elektrischem Kontakt in illustrativen, bewegten, fotografischen, usw Umsetzung darstellen.</p>
          <br />
        </div>
      </collapse>
      <collapse :title="'RECHERCHE'" :sub="true" class="sub">
        <div class="text-block">
          <h3>Haut</h3>
          <p>Mit der Haut kann Druck, Berührung und Vibrationen sowie Temperatur und Schmerz empfunden werden.</p>
          <h3>Rezeptoren in der Haut</h3>
          <p>→ Schmerz-Rezeptoren für die Schmerzempfindungen</p>
          <br />
          <p>→ Druck-Rezeptoren für die Formveränderungen der Haut (Gleichbleibender Druck wird nicht mehr wahrgenommen (geben nur bei Veränderung an)</p>
          <br />
          <p>→ Berührungs-/Tast-Rezeptoren für die kleinste Druckveränderungen (geben immer wieder an)</p>
          <br />
          <p>→ Temperatur-Rezeptoren für die Temperaturempfindungen</p>
          <br />
          <h3>Visuell wahrgenommenes Fühlen</h3>
          <p>Spiegelneuronen lassen uns gesehenes selber empfinden. Jeder weis wie sich alltägliche Berührungen mit unserer Haut anfühlen. Ich möchte diese Berührungen Visualisieren und die ausgelösten Gefühle beim Betrachter auslösen ohne bei der Visualisierung auf Illustrationen für Körperteile und Objekte zurückgreifen zu müssen.</p>
          <h3>Berührungsverändernde Faktoren</h3>
          <p>→ Es gibt äussere und innere Faktoren die eine Berührung verändern. Die Umgebung und Intention einer Berührung, sowie die innere Haltung und bereits frühere Erlebnisse mit dieser Berührung können ein und dieselbe Berührung bei Veränderung eines dieser Faktoren komplett verändern. Ausserdem Spielt die Materialität und Temperatur eine Rolle in der Empfindung.</p>
          <h3>Berührungsverändernde Faktoren</h3>
          <p>Dies sind Oberkategorien zu einer sehr diversen Paletten an Gefühlen. </p>
          <br />
          <p>→ Freude</p>
          <p>→ Trauer</p>
          <p>→ Ärger</p>
          <p>→ Furcht/Angst</p>
          <p>→ Überaschung</p>
          <p>→ Ekel</p>
          <br />
        </div>
      </collapse>
      <collapse :title="'INSPIRATION'" :sub="true" class="sub">
        <div class="text-block">
          <p>In Jannis Maroschecks Arbeiten entstehen durch den Kontakt von Grundformen durch Gestaltungsparameter Formen, die, wenn sie durch die gleichen "Regeln" (Parameter) entstanden sind, die gleiche visuelle Sprache sprechen. Durch das verändern der Gestaltungsparameter entstehen neue Aussagen.</p>
          <h4>→ Dies möchte ich in meine Arbeit einfliessen lassen.</h4>
          <br />
          <p>
          <a href="https://issuu.com/slanted/docs/slanted-shape-grammars-issuu" target="_blank">→ slanted shape grammars</a>
          </p>
          <img src="./assets/images/JannisMaroshek.png" />
          <br/>
          <br />
          <p>Im Atlas of Emotions sind für mich Gefühle sehr gut visuell dargestellt. Dabei bleibt Form und Farbe trotzdem Simpel und verständlich. Ausserdem geht es konzeptuell mehr in die Richtung die ich mir für meine Arbeit vorgenommen habe. </p>
          <br />
          <p>
          <a href="https://hi.stamen.com/the-shapes-of-emotions-72c3851143e2" target="_blank">→ Atlas of Emotions</a>
          </p>
          <img src="./assets/images/Atlasofemotion_1.png" />
          <img src="./assets/images/Atlasofemotion_2.png" />
          <br/>
          <h4>Ich habe durch die beiden Inputs eine visuelle Recherche gestartet und diese kategorisiert.</h4>
          <img src="./assets/images/Inspiration_Kontakt.jpg" />
          <br />
          <p>Zusätzlich habe ich Bilder von Abdrücken in der Haut gesammelt. Ich wollte sie zuerst selber an mir erstellen, habe danach aber gemerkt, dass dies sehr schlecht geht und nur mit sehr viel Zeit pro Abdruck möglich ist. Beim Suchen dieser Abdrücke bin ich auf die visuelle Parallele zur Prägung gekommen die ich potenziell in meine Hausaufgabe einfliessen lasse.</p>
          <br />
          <img src="./assets/images/Inspiration_Abdruck.jpg" />
          <br/>
          <br/>
        </div>
      </collapse>
      <collapse :title="'ENTWÜRFE'" :sub="true" class="sub">
        <div class="text-block">
          <h3>Skizzen</h3>
          <p>Erste Skizze nach Inspirationssammlung</p>
          <br />
          <img src="./assets/images/Skizze2.jpg" />
          <br/>
          <p>Kreatives Tief und Problemlösungsversuch festgehalten als Mindmaps</p>
          <br />
          <img src="./assets/images/Skizze4.jpg" />
          <br/>
          <p>Schulterberührung die durch andere Umgebungseinflüsse anders wahrgenommen wird</p>
          <br />
          <img src="./assets/images/Skizze6.jpg" />
          <br/>
          <h3>Digitale Entwürfe</h3>
          <p>Erste digitalen Entwürfe nach Inspirationssuche</p>
          <br />
          <img src="./assets/images/Digi_entwurf_1.png" />
          <br/>
          <p>Versuch Gestaltungsparameter festzulegen</p>
          <br />
          <img src="./assets/images/Digi_entwurf_1.png" />
          <br/>
          <p>Körperstellen mit unterschiedlicher Empfindlichkeit und Intimität</p>
          <br />
          <img src="./assets/images/Digi_entwurf_3.1.png" />
          <br/>
          <p>Vogelperspektive einer Berührung</p>
          <br />
          <img src="./assets/images/Digi_entwurf_4.png" />
          <br/>
          <p>Digitalisierung Prozess des Händedrucks</p>
          <br />
          <img src="./assets/images/Digi_entwurf_5.png" />
          <br/>
          <p>Digitalisierung Prozess des Nackendrückers</p>
          <br />
          <img src="./assets/images/Digi_entwurf_6.png" />
          <h3>Websiteentwurf</h3>
          <p>Das ist der Website Entwurf der für die Zusammenarbeit mit dem Programmieren Alex Lehmann essentiell war. Alle visuellen und inhaltlichen Entscheidungen wurden von mir gefällt und nicht von Alex beeinflusst. Das war mir sehr wichtig in unserer Zusammenarbeit, die Darstellung meiner Hausaufgabe sollte ganz meiner Feder entsprungen sein.</p>
          <br />
          <img src="./assets/images/Mobilewebsitelayouttest.jpg" />
          <br />
          <h3>3D Modell</h3>
          <p>Die Animationen durch ein 3D-Modell erlebbar machen. Somit hat sich mein, im Prozess neu gelernter 3D-Skill, als brauchbar erwiesen. Ich begann mit der Suche nach einem IPhone-App, mit dem ich mich selber digitalisieren kann. Dies erwies sich aber als nicht wirklich brauchbar, da die Geometrie sehr wild war und es viele Löcher im 3D-Modell gab. Ausserdem war es für mich unmöglich die 2D-Textur mit meinen Berührungen zu versehen. Ich fand bei vertiefter Recherche das Tool MakeHuman, mit welchem ich sehr gezielt, wenn auch nicht extrem genau und realitätsnah, mich in 3D nachbauen konnte. Die Textur die diesem Tool entsprang war viel einfacher zu bearbeiten als die des ersten Models.</p>
          <br />
          <img src="./assets/images/makehuman.png" />
          <img src="./assets/images/Textur3D.png" />
          <br />
          <br />
        </div>
      </collapse>
      <collapse :title="'TAGEBUCH'" :sub="true" class="sub">
        <div class="text-block">
          <h3>3.2.2021</h3>
          <p>Heute war ein harziger Tag. Ich begann mit der Ausführung meines geplanten Experiments zum Thema Abdrücke auf der Haut. Dieses stellte sich jedoch als Reinfall heraus, da den Hauptfaktor für die Entstehung von Abdrücken, die Zeit, aussen vor gelassen habe. Ich musste mir ein neues Experiment überlegen. Glücklicher weise kam mir die Idee zur Berührungsanalyse. Jedoch kamen weitere Probleme dazu. Welchen Berührungen soll ich mich widmen, wo soll ich mich Berühren, welche eindrücke soll ich festhalten.</p>
          <br />
          <p>Ich begann mit weiteren Recherchen zum Thema Haut. Fand sehr spannende Texte in einem Buch und führe eine Internetrecherche statt eines Experimentes zum Thema Abdrücke durch.</p>
          <br />
          <p>Ich begann mit ersten Tests einer grafischen Visualisierung von Berührungen, und wie sich gewisse Parameter wie Temperatur, Absicht, Härte und Textur auf eine abstrakte Weise zeigen lassen. Dabei stiess ich mit 2D-Grafiken auf eine Wand. Sie lösten in mir keine Gefühle aus. Ich begann Bilder zu suchen die meinen Vorstellungen entsprachen und stellte fest das auch diese das die gewünschten Gefühle nicht rüberbrachten. Ausser ein Bild. Dieses hatte eine Tiefe, Licht und Schatten, eine starke Textur und trotzdem bestand es aus simplen grafischen Formen.</p>
          <br />
          <h3>4.2.2021</h3>
          <p>Am heutigen Tag bin habe ich mich in die 3D Funktion von Photoshop vertieft, da die dritte Dimension für mich sehr naheliegend ist wenn es darum geht mehr mit Tiefe, Licht und schatten und Textur zu arbeiten. Ich habe viel Zeit mit Recherche zu Materialen gemacht und auch Bilder von Abdrücken in der Haut umgewandelt in 3D Texturen. Nach all dem sehr anstrengenden Tutorial schauen, recherchieren und im 3D-Raum navigieren brauchte ich eine Pause vom Computer. Das war unglaublich hilfreich in der Umsetzungssuche. Ich sass vor ein Blatt Papier und mir schoss sofort eine Idee in den Kopf die ich dann festhielt. Ein Perspektivenwechsel war die Lösung für das Weiterkommen. Anstatt im Querschnitt die Berührung auf der Haut zu zeigen, setzte ich sie nun aus der Vogelperspektive um. Das ist näher an dem, was wir auch sehen, wenn wir eine Berührung wahrnehmen und löste somit schon mehr der Probleme, die ich mit meinen bisherigen Umsetzungsexperimenten hatte.</p>
          <br />
          <h3>5.2.2021</h3>
          <p>Heute habe ich mich entschlossen mich noch tiefer ins 3D-Umfeld zu stürzen. Ich habe mir das Programm Blender oberflächlich beigebracht. Vertiefen will ich mich in die Texturierung. Jedoch bin ich was die die Gestaltung selbst und nicht die Technik angeht nicht viel weiter gekommen heute. Ich muss unbedingt ein Selbstexperiment machen was welche Berührungen bei mir auslöst. Jedoch ist mir seit meiner Themenwahl immer bewusster geworden wenn mich etwas/jemand berührt, was das in mir auslöst.</p>
          <br />
          <p>Heute nacht hatte ich eine zündende Idee.</p>
          <br />
          <p>Berührung visualisieren durch alles rund um die Berührung → Gesichtsausdruck, Geräusch, Umgebung, Situation, Körperteil?,</p>
          <br />
          <h3>6.2.2021</h3>
          <p>Heute habe ich begonnen meine Idee, mich mehr auf die Faktoren, die Einfluss nehmen wie eine Berührung aufgenommen wird, weiter auszuarbeiten. Ich begann zu skizzieren und es war richtig befreiend, weg vom Computer zu arbeiten. Mir würde bewusst, dass ich das Medium wechseln muss. Ich möchte unbedingt den Faktor Zeit einbeziehen. Ich wechsle für das wieder auf einen meiner bestehenden Fähigkeiten, die 2D-Animation. Ich habe viel überlegt wie ich die Form einer Berührung bestimmen kann.</p>
          <br />
          <h3>7.2.2021</h3>
          <p>Ich bin endlich auf die Idee gekommen, die Hände anderer farbig zu machen und mich berühren zu lassen. So kann ich die entstandene Form mit Illustrator zu einer Vektorform machen. Ich wählte die für mich prägendsten Berührungen aus die in mir jeweils andere Gefühle auslösten.</p>
          <br />
          <h3>8.2.2021</h3>
          <p>Heute habe ich mich in die Animation vertieft und die ersten beiden Animationen , Nackendrücker und Hand auf Schulter, fertiggestellt. Es war ein super Gefühl endlich zu wissen wohin ich mit meiner Arbeit will.</p>
          <br />
          <h3>9.2.2021</h3>
          <p>Heute habe ich die beiden letzten Animationen fertiggestellt und bei allen das Sounddesign gemacht. Es war ein unglaublich spannender und intensiver Tag, an dem ich viel Reflektiert habe und mich im Flow verloren habe.</p>
          <br />
          <h3>10.2.2021</h3>
          <p>Gestern Nacht ist mir die Idee gekommen dass ich die Animationen auf einem 3D-Modell erlebbar machen könnte. Somit hat sich mein im Prozess neu gelernter 3D-Skill als brauchbar erwiesen. Ich begann mit der Suche nach einem IPhone-App, mit dem ich mich selber digitalisieren kann. Dies erwies sich aber als nicht wirklich brauchbar, da die Geometrie sehr wild war und es viele Löcher im 3D-Modell gab. Ausserdem war es unmöglich die 2D-Textur mit meinen Berührungen zu versehen. Ich fand bei vertiefter Recherche das Tool MakeHuman mit welchem ich sehr gezielt wenn auch nicht extrem genau und realitätsnah mich in 3D bauen konnte. Es fühlte ich an als währe ich zurück in meine Kindheit versetzt, in der ich sehr oft die Sims spielte.</p>
          <br />
          <h3>11.2.2021</h3>
          <p>Ich begann mit einem Brainstorming für die Präsentation meiner Arbeit und kam dabei zum Schluss, dass eine Website das beste Erlebnis bei der Anschauung meiner Arbeit bietet. Nun fuhr ich mit einem Website-Prototyp im Indesign fort.</p>
          <br />
          <h3>12.2.2021</h3>
          <p>Heute habe ich mich mit einem guten Freund und Programmier, Alex Lehmann, getroffen. Er hat sich bereiterklärt die Programmierung der Website in angriff zu nehmen und dabei alle gestalterischen und inhaltlichen Entscheide mir zu überlassen. Dies war mir sehr wichtig, da ich zwar die technischen Skills des Programmierens noch nicht besitze, aber ich in voller Kontrolle meiner Hausaufgabe bleiben wollte.</p>
          <br />
          <h3>13.2.2021</h3>
          <p>Heute habe ich mich damit auseinandergesetzt wie ich die Hausaufgabe als Teil meines Portfolios umsetzen kann. Ein einfaches Blatt mit Link ist mir zu wenig und ich stellte mir die Frage ob auch alle Experten die meine Hausaufgabe anschauen auch wirklich den Link eingeben werden und sich die Website anschauen werden. Also habe ich noch ein A3-Blatt ausgestaltet, welches Ausschnitte der Animationen und das 3D-Modell zeigt. Der Konzepttext ist da auch drauf und einige Spezifikationen der Website.</p>
          <br />
        </div>
      </collapse>
      <collapse :title="'NOCH MEHR'" :sub="true" class="sub">
        <div class="text-block">
          <h3>Mein gesamter Prozess ist auf Notion festgehalten. </h3>
          <p>
          <a href="https://www.notion.so/laburoso/KONTAKT-8499d4f31ae041449cc99c2130d9487d" target="_blank">→ Notion</a>
          </p>
          <br/>
          <br/>
        </div>
      </collapse>
    </collapse>
  </section>
  <modal :title="'ECKEL/ÄRGER'" :open="modalState.openShoulder" :animation="require('./assets/animations/shoulder.mp4')">
    <div class="text-block">
      <p>
         <br />
        Die Hand meines Chefs während der Lehre auf meiner Schulter,
        die für mich für die Unterdrückung meiner Kreativität steht.
        Sie löst in mir Frustration und Ekel aus. <br />
      </p>
    </div>
  </modal>
  <modal :title="'TRAUER/FURCHT'" :open="modalState.openNeck" :animation="require('./assets/animations/neck.mp4')">
    <div class="text-block">
      <p>
         <br />
        Der Griff eines Mitschülers am Nacken, der mich herunterdrückt, steht für mich für meine Grundschulzeit. <br />
        Er setzt in mir Angst und Machtlosigkeit frei. <br />
      </p>
    </div>
  </modal>
  <modal :title="'FREUDE'" :open="modalState.openBack" :animation="require('./assets/animations/back.mp4')">
    <div class="text-block">
      <p>
         <br />
        Die Hand meiner Freundin auf meinem Rücken, die mich massiert,
        steht für mich für Entspannung und gibt mir das Gefühl,
        zu Hause zu sein. Sie löst in mir Wohlsein und Wärme aus.  <br />
      </p>
    </div>
  </modal>
  <modal :title="'ÜBERRASCHUNG/FREUDE'" :open="modalState.openHand" :animation="require('./assets/animations/hand.mp4')">
    <div class="text-block">
      <p>
         <br />
        Der Händedruck des Berufsschulleiters bei der Abgabe meines Zeugnisses und meines Lehrausweises,
        der für das Freikommen aus meiner Lehre und das Eintauchen in die Selbstständigkeit steht.
        Der setzt in mir Aufregung und Erleichterung frei.
        <br />
      </p>
    </div>
  </modal>
</template>

<style lang="scss">
html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: 'Bandeins-Strange';
  src: url('./assets/fonts/BandeinsStrangeVariable.woff2') format('woff2 supports variations'),
       url('./assets/fonts/BandeinsStrangeVariable.woff2') format('woff2-variations');
  font-stretch: 100% 800%;
  font-weight: 200 800;
  font-display: block;
}

h1 {
  font-family: 'Bandeins-Strange';
  font-size:  270%;
  font-weight: 700;
  font-stretch: 100%;

  margin: 0;
}
h2 {
  font-family: 'Bandeins-Strange';
  font-size: 270%;
  font-weight: 300;
  font-stretch: 700%;

  margin: 0;
}

p {
  font-family: 'Bandeins-Strange';
  font-size: 135%;
  font-weight: 500;
  font-stretch: 200%;

  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  border-top: 1px solid black;
  width: 100%;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sketch-section {
  flex-direction: column;
}

.sketch-container {
  height: 60vh;
  width: 100%;
}

.button-container {
  width: 100%;
  height: 10vh;
  display: flex;
  max-width: 100%;
  justify-content: space-evenly;
}

.button {
  cursor: pointer;
  border: 1px solid;
  height: 10vh;
  font-size: 65%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-wrap: wrap;
  flex-grow: 1;
}

.sub {
  border-bottom: 1px solid black;
  h2 {
    font-size: 200%;
    font-stretch: 500%;
  }
}

.text-block {
  width: 100%;
  p {
    max-width: 700px;
    a {
      margin-left: 0;
      color: black;
    }
  }
  img {
    width: 100%;
  }
  h4 {
    font-size: 135%;
    max-width: 700px;
    font-weight: 800 !important;
  }
  h3 {
    font-size: 150%;
    max-width: 700px;
    font-weight: 800 !important;
  }
}

</style>
